/************ Custom Font ************/
@font-face { 
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default CSS ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth; /* Slides smoothly instead of abrupt */
  scroll-padding-top: 75px;
}

body {
  font-weight: 400; /* Normal */
  overflow-x: hidden; /* Horizontal */
  position: relative;
  background: #151515 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

.header-title span {
  display: inline-block;
  animation: wave 2s linear infinite;
}

.header-title span:nth-child(2) {
  animation-delay: 100ms;
}

.header-title span:nth-child(3) {
  animation-delay: 200ms;
}

.header-title span:nth-child(4) {
  animation-delay: 300ms;
}

.header-title span:nth-child(5) {
  animation-delay: 400ms;
}

.header-title span:nth-child(6) {
  animation-delay: 500ms;
}

.header-titlespan:nth-child(7) {
  animation-delay: 600ms;
}

.header-title span:nth-child(8) {
  animation-delay: 700ms;
}

.header-title span:nth-child(9) {
  animation-delay: 800ms;
}

 @keyframes wave {
  50% {
    transform: translateY(-10px);
  }
 }


/************ Navbar CSS ************/
nav.navbar {
  padding: 18px 0;
  position: fixed; /* must be always fixed and visible at the top  */
  width: 100%;
  top: 0;
  z-index: 9999; /* to make this always visible and in front of an element with a lower stack order  */
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #3137876f ;
}
nav.navbar a.navbar-brand {
    width: 9%; /* for the logo on topmost left */
}
nav.navbar .navbar-nav .nav-link.navbar-link { /* styling for links */
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.5;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
  text-decoration: underline;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute; /* place image inside a tag */
    background-color: #ffffff;
    border-radius: 50%; /* make it round */
    transform: scale(0); /* white background not visible yet */
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1); /* white background now visible in its orig size */
}
.social-icon a img {
	width: 50%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}

/* filter used for nav bar image icons to invert color from white to black*/
.social-icon a:hover img { 
	/* filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%); */
  filter: invert(34%) sepia(31%) saturate(622%) hue-rotate(259deg) brightness(89%) contrast(87%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.navbar .logo-header {
  animation: updown 2s linear infinite;
} 

@keyframes updown {
  0% {
      transform: translateY(-7px);
  }
  50% {
      transform: translateY(7px);
  }
  100% {
      transform: translateY(-7px);
  }
}

.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #d084d1;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 250px 0;
  height: 1000px;
  /* Source: https://wallhaven.cc/ */ 
  /* background-image: linear-gradient(rgba(50,50,50,0.5), rgba(50,50,50,0.5)), url('./assets/img/banner-bg.png'); */
  
  background-image:  linear-gradient(0deg, rgba(28,29,60,1) 0%, rgba(10,14,27,0.40242034313725494) 91%), url('./assets/img/golden-gate.jpeg');  
  background-position: top center;

}
.banner .tagline { /* Welcome to my Portfolio */
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  /* background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%); */
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  font-size: 20px;
  margin-bottom: 40px;
  display: inline-block;
}
.banner .space-top {
  margin-top: 800px;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
}
.banner p {
  margin-top: 30px;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button { /* Let's connect */
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px; /* because the arrow moves towards the right*/
}

.txt-rotate > .wrap { /* imitating the line at the end of the text while typing */
  border-right: 0.08em solid #666;
}

/************ About Me Css ************/
.about-me {
  padding: 150px 0 150px 0;
  height: 100%;
  /* background-image: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(250,69,132,0.5648460067620799) 39%, rgba(104,108,31,1) 77%, rgba(0,212,255,1) 100%), url('./assets/img/aboutme-bg.png'); */
  /* background-image: url('./assets/img/banner-bg_3.png');  */
  background: linear-gradient(0deg, rgba(21,21,21,1) 0%, rgba(28,29,60,1) 100%);  
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

}

.about-me h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}

.about-me p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.about-me .about-me-col{
  /* background: linear-gradient(to top, #3f21466c 0%, #7769a142 100%); */

  background: linear-gradient(0deg, rgba(30,27,34,1) 0%, rgba(45,58,89,1) 100%);
  
  border-radius: 60px;
  padding: 70px;
}

/************ Skills Css ************/
.skill {
  padding: 100px 0 50px 0;
  position: relative;
}
.skill-bx { /* internal container box with rounded corners */
  /* background: linear-gradient(to top, #1A0C2E 0%, #7769A1 100%); */
  background: linear-gradient(0deg, rgba(30,27,34,1) 0%, rgba(47,65,108,1) 100%);
  border-radius: 60px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2 { /* Skills header title  */
	font-size: 45px;
	font-weight: 700;
}

.skill p { /* Skills description or statement */
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img { /* icon images */
  width: 50%;
  margin: 0 auto 15px auto;
}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  /* padding: 20px; */
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(to top, #E69CD5 0%, #FFB370 100%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}


.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  margin: 15px;
}

.proj-txtx h5 {
  margin: 5px;
}
.proj-txtx h5:hover, .proj-txtx a:hover {
  text-decoration: underline;
  cursor: pointer;
}


.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}


.proj-txtx a {
  font-style: normal;
  /* font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px; */
  text-decoration: none;
}


/* .background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
} */

/************ Contact Css ************/
.contact {
  /* background: linear-gradient(to top, #151515 0%, #443662 50%, #151515 100%); */
  padding: 60px 0 200px 0;
}

.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #605974;
  border-radius: 11px;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin: 15px 0 10px 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {


  color: #fff;
}
.contact form button::before {
  content: "";
  background: #243e71;
  border-color:  #243e71;
  border-radius: 11px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact .contact-me {
  margin-bottom: 10%;
  width: 95%;
}

.contact .contact-row {
  padding-left: 20%;
}

.contact .contact-icon {
  width: 10%;
  filter: invert(34%) sepia(31%) saturate(622%) hue-rotate(259deg) brightness(89%) contrast(87%);
}


/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  /* background-image: url('./assets/img/footer-bg.png'); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}


